<template>
  <div>
    <div class='relative pt-4 lg:pt-16 pb-4 lg:pb-16'>
      <content-background-cover
        class='z-0'
        :top-offset='0'
        :custom-css='backgroundGradientTop' />
      <khrs-special-videos />
    </div>
    <welcome-sponsors-carousel class='mt-16' />
    <welcome-conference-banner 
      class='py-16'
      :custom-css-background='backgroundGradientMiddle' />
    
  </div>
</template>

<script>
import ContentBackgroundCover    from '@/components/ContentBackgroundCover.vue'
import WelcomeSponsorsCarousel   from '@/components/welcome/WelcomeSponsorsCarousel.vue'
import WelcomeConferenceBanner   from '@/components/welcome/WelcomeConferenceBanner.vue'
import KhrsSpecialVideos         from '@/components/khrs-virtual/KhrsSpecialVideos.vue'

export default {
  name: 'KhrsSpecial',
  components: {
    ContentBackgroundCover,
    WelcomeSponsorsCarousel,
    WelcomeConferenceBanner,
    KhrsSpecialVideos,
  },
  computed: {
    backgroundGradientTop () {
      return 'background: radial-gradient(64.29% 64.29% at 50.13% 79.3%, #060001 0%, #202020 100%);'
    },
    backgroundGradientMiddle () {
      return 'background: linear-gradient(180deg, #FFFFFF 0%, #E1E6E844 100%);'
    },
  },
}
</script>
