<template>
  <div>
    <div class='lg:flex lg:flex-col lg:justify-between lg:items-stretch mt-8 relative'>
      <div class='session-detail-box fixed lg:static lg:flex-grow overflow-y-auto px-2 lg:px-4 top-16 z-40 lg:z-0 lg:rounded-t-md'
        style='background-color:var(--lightColor);'
        :class='sessionDetailsBoxClass'>
        <button>
          <x-icon 
            class='absolute text-gray-500 h-5 block lg:hidden'
            @click='closeMobileSessionDetail'
            style='top: 1rem; right: 1rem;' />
        </button>
        <div class='flex flex-col lg:flex-row justify-center items-center lg:items-start lg:justify-between'>
          <div><img :src='leftBannerUrl' class='w-64'></div>
          <div class='flex-grow'>
            <div class='flex flex-row items-center justify-start py-4 px-4 gap-x-4' style='background-color:var(--inputColor);'>
              <div class='row-centered gap-x-4'>
                <img 
                  src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image_white.png'
                  class='h-8 lg:h-12' />
                <img 
                  src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/khrs_wordmark_white.png'
                  class='h-4 lg:h-6' />
              </div>
              <h4 class='text-normal lg:text-2xl text-white uppercase'>{{selectedVideo.title}}</h4>
            </div>
            <vod-content-vimeo
              :showing-content='selectedVideo' />
          </div>
          <div><img :src='rightBannerUrl' class='w-64'></div>
        </div>
      </div>
      <div 
        class='w-full flex flex-col lg:flex-row justify-center items-center px-2 py-2 gap-y-2 lg:gap-y-0 lg:gap-x-2 lg:rounded-b-md'
        style='background-color:var(--lightColor); bottom: -6rem;' >
        <action-box v-for='(videoLink, index) in videoLinks'
          :key='`action-box-${index}`'
          :title-text='videoLink.title'
          :background-color='colorsInRange[1]'
          box-height='4rem'
          @click.native='selectVideo(index)'
          :style='selectedStyle(index)'><span :innerHTML='videoLink.videoLinkContent'></span></action-box>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { XIcon }    from '@vue-hero-icons/outline'
import VodContentVimeo   from '@/components/contents/VodContentVimeo.vue'
import ActionBox    from '@/components/ActionBox.vue'


export default {
  name: 'KhrsSpecialVideos',
  components: {
    XIcon,
    VodContentVimeo,
    ActionBox,
  },
  data () {
    return {
      videoLinks: [
        {
          title: 'Opening Address',
          vimeoVideoId: '698884492',
        },
        {
          title:        'Welcome Message',
          vimeoVideoId: '698884492',
        },
        {
          title:        'Thanks to Our Sponsors',
          vimeoVideoId: '698884492',
        },
        {
          title:        'Thank You Message',
          vimeoVideoId: '698884492',
          
        },
        {
          title:        '홍보영상',
          vimeoVideoId: '698884492',
          
        },
        {
          title:         'gift',
          vimeoVideoId:  '698884492',
          videoLinkContent: `<gift-icon />`
        },
      ],
      selectedVideoIndex: 0,
      showMobileDetail: false,
    }
  },
  computed: {
    ...mapGetters('events', [
      'colorsInRange',
    ]),
    sessionDetailsBoxClass () {
      return this.showMobileDetail ? 'block' : 'hidden'
    },
    selectedVideo () {
      return this.videoLinks[this.selectedVideoIndex]
    },
    leftBannerUrl () {
      return 'https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image.png'
    },
    rightBannerUrl () {
      return 'https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/conference_key_image.png'
    },
  },
  methods: {
    openGift () {
      console.log('open gift')
    },
    isSelectedVideo (index) {
      return (this.selectedVideoIndex === index) ? 'selected' : ''
    },
    selectVideo (index) {
      if (index === 5) {
        this.openGift()
      } else {
        this.openMobileSessionDetail()
      }
      this.selectedVideoIndex = index
    },
    selectedStyle (index) {
      return (this.selectedVideoIndex === index) ? `background-color: ${this.colorsInRange[0]}; opacity: 1; font-weight: 500; border-radius: 4px; border: transparent;` : ''
    },
    closeMobileSessionDetail () {
      this.showMobileDetail = false
    },
    openMobileSessionDetail () {
      this.showMobileDetail = true
    }
  },
}
</script>

<style lang='scss' scoped>
  .session-detail-box {
    height: calc(100vh - 4rem);
    width: 100%;
  }

  @media (min-width: 1024px) {
    .session-detail-box {
      display: block;
      height: auto;
      width: auto;
    }
  }

</style>
