<template>
  <div class='action-box opacity-80 hover:opacity-100 hover:text-gray-800 font-thin hover:font-base cursor-pointer p-4 flex flex-col justify-center items-start border solid'
    :style='boxStyle'>
    <h1 
      class='text-center mb-2 lg:mb-0 capitalize w-full text-sm' 
      :style='`color: ${textColor}`'>
      {{titleText}}
    </h1>
    <h3 class='uppercase flex flex-row justify-start items-center gap-x-2'>
      <slot></slot>
    </h3>
  </div>
</template>

<script>

export default {
  name: 'ActionBox',
  props: [
    'backgroundColor',
    'titleColor',
    'titleText',
    'isSelected',
    'boxHeight',
    'selectedStyle',
  ],
  computed: {
    bgColor () {
      return (this.backgroundColor) ? this.backgroundColor : '#00000022'
    },
    textColor () {
      return (this.titleColor) ? this.titleColor : '#333'
    },
    heightStyle () {
      return (this.boxHeight) ? `height: ${this.boxHeight};` : `height: 240px;`
    },
    boxStyle () {
      return `background-color: ${this.bgColor}; ${this.heightStyle}; border-color: #CCC;`
    },
  },
}
</script>

<style lang='scss' scoped>
  .action-box {
    width: 100%;
    height: auto;
  }

  @media (min-width: 1024px) { 
    .action-box {
      width: 300px; 
      height: 260px;
    }
  }

</style>
