<template>
  <div v-if='selectedContentSourceId'>
    <vimeo-player
      ref='vodPlayer'
      :options='options'
      :video-id='selectedContentSourceId'
      @play='playVod'
      @seeked='seekVod'
      @ended='endVod'
      @timeupdate='timeupdateVod'
      @fullscreenchange='fullscreenUpdate' />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import isEmpty from 'lodash/isEmpty'
import { createStoreon } from 'storeon'
import { crossTab }      from '@storeon/crosstab'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  props: [
    'showingContent', 
    'autoplay', 
    'mute',
    'loop',
    'defaultVolume',
    'session',
    'speed',
  ],
  name: 'VodContentVimeo',
  data () {
    return {
      videoTracker: null,
      lastPlayedDone: '',
      options: {
        autoplay: this.autoplay,
        responsive: true,
        title: false,
        byline: false,
        speed: this.speed || false,
        muted: this.mute,
        loop: this.loop,     
      },
      playVodForTheFirstTime: true,
      storeViewingVideoCount: null,
      increaseViewingVideoCount: null,
    }
  },
  watch: {
    'contentId': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal && this.sameVideoScheduledStartDate()) {
          this.getLastWatchedTime(this.trackWatchedVideoParams).then((resp) => {
            this.lastPlayed = resp.lastWatchedTime
            this.lastPlayedDone = resp.watchedStatus 
            if (resp.lastWatchedTime > 1 && resp.watchedStatus !== 'done') {
              this.$refs.vodPlayer.player.setCurrentTime(resp.lastWatchedTime - 1)
            }
          })
        }
      }
    }
  },
  computed: {
    ...mapFields('watchedVideos', [
      'lastPlayed',
      'playStartTime',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'isCmccmeEvent',
    ]),
    hasShowingContent () {
      return !isEmpty(this.showingContent)
    },
    selectedContentSourceId () {
      if (this.hasShowingContent) {
        if (this.showingContent.vimeoVideoId) {
          //from poster api
          return this.showingContent.vimeoVideoId
        } else if (this.showingContent.data && this.showingContent.data.sourceUrl) {
          //from contents api
          let sourceUrlIdPart = this.showingContent.data.sourceUrl.split('https://vimeo.com/')[1]
          return sourceUrlIdPart.split('/')[0]
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    contentId () {
      return this.hasShowingContent ? this.showingContent.id : 0
    },
    sessionId () {
      return parseInt(this.$route.query.session_id)
    },
    playedTimes () {
      return [[0, this.lastPlayed]]
    },
    trackWatchedVideoParams () {
      return {
        event_id: this.showingEventId,
        session_id: this.sessionId,
        content_id: this.contentId,
        video_player: 'vimeo',
        start_time: this.playStartTime,
        watched_status: '',
        played_times: this.playedTimes,
      }
    },
  },
  methods: {
    ...mapMutations([
      'setIsFullscreenVideo'
    ]),
    ...mapActions('userActions', [
      'createUserAction',
    ]),
    ...mapActions('watchedVideos', [
      'trackWatchedVideo',
      'getLastWatchedTime',
    ]),
    sameVideoScheduledStartDate () {
      return this.session ? dayjs().tz('Asia/Seoul').isSame(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date') : false
    },
    fullscreenUpdate (resp) {
      this.setIsFullscreenVideo(resp.fullscreen)
    },
    endVod (data) {
      if (this.isCmccmeEvent && this.sameVideoScheduledStartDate()) {
        this.lastPlayed = data.seconds
        let params = {
          event_id: this.showingEventId,
          session_id: this.sessionId,
          content_id: this.contentId,
          video_player: 'vimeo',
          start_time: this.playStartTime,
          watched_status: 'done',
          played_times: [[0, data.seconds]],
        }
        this.trackWatchedVideo(params)
        clearInterval(this.videoTracker)
        this.$emit('end-vod')
      }
      this.createUserAction({
        userActionName: 'end_vod_session_video',
        userActionData: {
          event_id: this.showingEventId,
          session_id: this.sessionId,
          content_id: this.contentId,
          video_player: 'vimeo'
        }
      })
    },
    seekVod (data) {
      if (this.isCmccmeEvent && this.sameVideoScheduledStartDate()) {
        if(this.lastPlayed < data.seconds) {
          this.$refs.vodPlayer.player.setCurrentTime(this.lastPlayed)
        }
      }
    },
    timeupdateVod (data) {
      if (this.isCmccmeEvent && this.sameVideoScheduledStartDate()) {
        if (data.seconds -1 < this.lastPlayed && this.lastPlayed < data.seconds) {
          this.lastPlayed = data.seconds
        }
      }
    },
    submitTrackWatchedVideo () {
      this.trackWatchedVideo(this.trackWatchedVideoParams)
    },
    playVod () {
      if (this.playVodForTheFirstTime) {
        if (this.isCmccmeEvent && this.sameVideoScheduledStartDate()) {
          if (this.lastPlayed > 1 && this.lastPlayedDone !== 'done') {
            this.$refs.vodPlayer.player.setCurrentTime(this.lastPlayed - 1)
          }
          this.playStartTime = dayjs().format()
          this.videoTracker = setInterval(() => {
            if (this.sameVideoScheduledStartDate()) {
              this.submitTrackWatchedVideo()
            }
          }, 30000)
          this.initStoreViewingVideoCount()
        }
        this.playVodForTheFirstTime = false
        if (!this.autoplay && this.defaultVolume) {
          this.$nextTick(() => {
            this.$refs.vodPlayer.player.setVolume(this.defaultVolume)
          }) 
        }
        if (!this.autoplay && this.sessionId && this.contentId) {
          this.createUserAction({
            userActionName: 'click_vod_session_video_start_button',
            userActionData: {
              event_id: this.showingEventId,
              session_id: this.sessionId,
              content_id: this.contentId,
              video_player: 'vimeo'
            }
          })
        }
      }
    },
    initStoreViewingVideoCount () {
      this.increaseViewingVideoCount = store => {
        store.on('@init', () => ({ viewingVideoCount: 0 }))
        store.on('increase', ({ viewingVideoCount }) => ({ viewingVideoCount: viewingVideoCount + 1 }))
      }
      this.storeViewingVideoCount = createStoreon([
        this.increaseViewingVideoCount,
        crossTab()
      ])
      this.storeViewingVideoCount.dispatch('increase')
      this.storeViewingVideoCount.on('@changed', (store) => {
        if (store.viewingVideoCount > 1) {
          clearInterval(this.videoTracker)
          this.$alert('평점을 위한 시청 기록 측정을 위하여 강의 영상은 한 개의 페이지에서만 시청 가능합니다. 본 탭은 5초 후 자동으로 닫아질 예정입니다.', {
            confirmButtonText: 'Close',
            type: 'warning'
          })
          setTimeout(() => {
            window.open('Programs', '_self')
          }, 5000)
        }
      })
    },
  },
  beforeDestroy () {
    if (this.isCmccmeEvent && this.playStartTime) {
      clearInterval(this.videoTracker)
    }
  },
}
</script>
